<template>
    <v-dialog v-model="dialog" persistent max-width="700">
        <v-card>
            <v-card-title>
                <div class="d-flex justify-space-between w-100">
                    <div class="d-flex align-center secondary--text">
                        <v-icon class="mr-1">info</v-icon>
                        Invitation Error
                    </div>
                    <v-btn @click="dialog = false" icon><v-icon>clear</v-icon></v-btn>
                </div>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-divider></v-divider>

            <v-card-text class="mt-3">
                <div class="d-flex flex-column" v-if="errorType === DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR">
                    Your invitations haven't been sent because an error has occurred while fetching the latest distributed material.
                    <span class="mt-3">You can try to fetch and invite again or invite the users to empty instances and distribute material later.</span>
                </div>
                <div class="d-flex flex-column" v-else-if="errorType === emailValidationErrorTypes.INVALID_EMAILS_ERROR">
                    <span v-if="isSignUpDialog">Your sign up request hasn't been sent because the following email might be invalid:</span>
                    <span v-else>Your invitations haven't been sent because the following emails might be invalid:</span>
                    <ul class="mt-3">
                        <li v-for="(email, index) in invalidEmails" :key="index">
                            <span class="font-weight-bold caption">{{ email.email }}</span>
                        </li>
                    </ul>
                </div>
                <div v-else-if="errorType === emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR">
                    Our backend server could not validate the list of emails you provided.
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="errorType === emailValidationErrorTypes.INVALID_EMAILS_ERROR" @click="dialog = false" color="primary" text>
                    <span v-if="isSignUpDialog">review email</span>
                    <span v-else>review emails</span>
                </v-btn>
                <v-btn v-else-if="errorType === emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR" @click="validateAgain" color="primary" text>
                    validate again
                </v-btn>
                <v-btn v-else-if="errorType === DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR" @click="fetchSnapshotsAndInvite" color="primary" text>
                    fetch and invite again
                </v-btn>
                <v-btn v-if="errorType === DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR" @click="inviteWithoutFetchingDistributed" color="error" text>
                    invite to empty instances
                </v-btn>
                <v-btn v-else @click="inviteAnyway" color="error" text>
                    <span v-if="isSignUpDialog">request signup anyway</span>
                    <span v-else>invite anyway</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { emailValidation } from '@/mixins/validateEmail'

export default {
    name: 'InvitationErrorsDialog',
    mixins: [emailValidation],
    props: {
        showDialog: Boolean,
        invalidEmails: Array,
        errorType: { type: String, default: null },
        isSignUpDialog: { type: Boolean, default: false }
    },
    data() {
        return {
            dialog: false,
            DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR: 'fetchingDistributedError'
        }
    },
    methods: {
        inviteAnyway: function () {
            this.$emit('proceedWithTheInvitation', { value: true })
            this.dialog = false
        },
        validateAgain: function () {
            this.$emit('validateEmailsAgain', { value: true })
            this.dialog = false
        },
        fetchSnapshotsAndInvite: function () {
            this.$emit('fetchAndInviteAgain', { value: true })
            this.dialog = false
        },
        inviteWithoutFetchingDistributed: function () {
            this.$emit('inviteWithoutFetchingDistributed', { value: true })
            this.dialog = false
        }
    },
    watch: {
        showDialog: function (nextVal) {
            this.dialog = nextVal
        }
    }
}
</script>
